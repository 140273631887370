'use client';

import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/components/Blocks/createBlock';
import ImageAsset from '@/components/ImageAsset';
import Box from '@/components/ui/Box';
import Divider from '@/components/ui/Divider';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { gql } from '@/graphql/__generated__';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { useBreakpointMin } from '@/lib/utils/useBreakpoints';
import { vars } from '@/styles';
import { useGSAP } from '@gsap/react';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { toId, toStringOrNull } from '@liquorice/utils';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
import { BlockContainer } from '../../BlockContainer';
import * as styles from './ScrollContentBlock.css';

const SCROLL_ITEM_FRAGMENT = gql(`
  fragment scrollItemEntry on scrollItem_Entry {
    imageSingle {
      ...ImageAsset
    }
    title
    content: htmlContentSimple
    id
  }
`);

const SCROLL_CONTENT_BLOCK = gql(`
  fragment scrollContentBlock on blocks_scrollContent_BlockType {
      heading
      scrollItemMultiple {
        ...scrollItemEntry
      }
  }   
`);

const scrollItemParser = createFragmentArrayParser(SCROLL_ITEM_FRAGMENT, (data) => {
  return data.map((item) => {
    return {
      title: toStringOrNull(item.title),
      content: cleanHtml(item.content),
      imageSingle: item.imageSingle,
      id: toId(item.id),
    };
  });
});

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ScrollContentBlock = createBlock(SCROLL_CONTENT_BLOCK, ({ data, meta }) => {
  if (!data) return null;

  const main = useRef<HTMLDivElement | null>(null);
  const lg = useBreakpointMin('lg');

  useGSAP(
    () => {
      const mm = gsap.matchMedia();

      mm.add(
        {
          // small: `(max-width: 1023px)`,
          large: '(min-width: 1024px)',
        },
        (ctx) => {
          const images = gsap.utils.toArray<HTMLDivElement>('.imageWrapper');
          const contents = gsap.utils.toArray<HTMLDivElement>('.content');
          const contentDivider = gsap.utils.toArray<HTMLDivElement>('#content-divider');
          const { large } = ctx.conditions ?? {};

          if (large) {
            const timeline = gsap.timeline({
              scrollTrigger: {
                trigger: main.current,
                start: 'center center',
                end: 'bottom -100%',
                scrub: true,
                pin: true,
                // markers: true,
              },
            });
            images.forEach((image, index) => {
              timeline.to(image, {
                transform: `translateY(0%)`,
                stagger: 0.1,
              });
              timeline.fromTo(
                contents[index],
                {
                  opacity: index === 0 ? 1 : 0.25,
                },
                {
                  opacity: 1,
                }
              );
              timeline.fromTo(
                contentDivider[index],
                {
                  opacity: index === 0 ? 1 : 0,
                },
                {
                  opacity: 1,
                },
                '<'
              );
              timeline.to(contents[index], {
                opacity: index === contents.length - 1 ? 1 : 0.25,
              });
              timeline.to(
                contentDivider[index],
                {
                  opacity: index === contents.length - 1 ? 1 : 0,
                },
                '<'
              );
              timeline.to(image, {
                transform: index === images.length - 1 ? `translateY(0%)` : `translateY(-100%)`,
                opacity: index === images.length - 1 ? 1 : 0,
                stagger: 0.1,
              });
            });
          }
        }
      );
    },
    { scope: main }
  );

  const scrollItems = scrollItemParser(data.scrollItemMultiple);

  if (!main) return null;

  return (
    <BlockContainer ref={main} meta={meta} paddingY="6xl">
      <BlockHeader
        heading={data.heading}
        cx={{ mB: '5xl', zIndex: 'foreground', position: 'relative' }}
        alignItems="center"
        colorSet="white"
        paper
      />
      <Grid cx={{ zIndex: 'background', position: 'relative', rowGap: !lg ? '5xl' : undefined }}>
        {lg && (
          <Grid.Col md={5}>
            <Flex direction="column" alignItems="center" rowGap="md">
              {scrollItems.map((item) => {
                return (
                  <Box key={`${item.id}-content`} className="content" cx={{ position: 'relative' }}>
                    <Divider direction="vertical" id="content-divider" className={styles.divider} />
                    <Txt as="h3" variant="h3">
                      {item.title}
                    </Txt>
                    <Txt html>{item.content}</Txt>
                  </Box>
                );
              })}
            </Flex>
          </Grid.Col>
        )}
        {lg && (
          <Grid.Col offsetMd={1} md={6}>
            {scrollItems.map((item, i) => {
              return (
                <Box
                  key={`${item.id}-image`}
                  className="imageWrapper"
                  style={{
                    position: 'absolute',
                    top: 0,
                    transform: `translateY(${i * 200}%)`,
                    zIndex: vars.zIndex.background,
                  }}>
                  <ImageAsset data={item.imageSingle} />
                </Box>
              );
            })}
          </Grid.Col>
        )}
        {!lg &&
          scrollItems.map((item) => {
            return (
              <Grid.Col key={`${item.id}-mobile`}>
                <Flex rowGap="2xl">
                  <ImageAsset data={item.imageSingle} />
                  <Box>
                    <Txt as="h3" variant="h3">
                      {item.title}
                    </Txt>
                    <Txt html>{item.content}</Txt>
                  </Box>
                </Flex>
              </Grid.Col>
            );
          })}
      </Grid>
    </BlockContainer>
  );
});

export default ScrollContentBlock;
