'use client';

import { useEffect } from 'react';
import { create as createStore } from 'zustand';

export type BooleanValueState = {
  value: boolean;
  setValue: (value: boolean) => void;
};

/** A store to record the state of Next Hydration */
export const useNextHydratedStore = createStore<BooleanValueState>((set) => ({
  value: false,
  setValue: (value: boolean) => set({ value }),
}));

/**
 * A hook to records and returns the state of Next JS hydration
 */
export const useNextHydrated = () => {
  const { value, setValue } = useNextHydratedStore();

  useEffect(() => {
    setValue(true);
    return () => setValue(false);
  }, [setValue]);
  return value;
};
