'use client';

import { createPersistStore } from '@/lib/utils/createPersistStore';
import { AnimatePresence, motion } from 'framer-motion';
import IconButton from '../IconButton';
import Box from '../ui/Box';
import Container from '../ui/Container';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import * as styles from './Banner.css';

type BannerProps = {
  content?: string | null;
  id?: string | null;
};

const Banner = ({ content, id }: BannerProps) => {
  const bannerStore = createPersistStore(id ?? '_');

  const { value, setValue } = bannerStore();

  const isSet = value !== null;

  return (
    <AnimatePresence>
      {content && !isSet && (
        <Box as={motion.div} key={id} exit={{ height: 0 }} colorSet="blue" paper cx={{ pY: 'sm' }}>
          <Container>
            <Grid>
              <Grid.Col xs={11}>
                <Txt html variant="md">
                  {content}
                </Txt>
              </Grid.Col>
              <Grid.Col xs={1} cx={{ display: 'flex', justifyContent: 'end', alignItems: 'start' }}>
                <IconButton
                  variant="icon"
                  icon="close"
                  className={styles.closeIcon}
                  onClick={() => setValue(false)}
                />
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      )}
    </AnimatePresence>
  );
};

export default Banner;
