'use client';

import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/components/Blocks/createBlock';
import ImageAsset from '@/components/ImageAsset';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import Video from '@/components/Video';
import { gql } from '@/graphql/__generated__';
import { parseColorScheme } from '@/lib/parsers/colorScheme';
import { parseLinkField } from '@/lib/parsers/linkField';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { toBoolean, toStringOrNull } from '@liquorice/utils';
import { BlockContainer } from '../../BlockContainer';

export const TWO_COLUMN_CONTENT_BLOCK = gql(`
  fragment twoColumnContentBlock on blocks_twoColumnContent_BlockType {
      heading
      content: htmlContentDefault
      linkField {
        ...linkField
      }
      imageSingle {
        ...ImageAsset
      }
      video
      caption: label
      blockScheme {
        label
      }
      flipAlignment: lightswitch
  }   
`);

const TwoColumnContentBlock = createBlock(TWO_COLUMN_CONTENT_BLOCK, ({ data, meta, ...props }) => {
  if (!data) return null;

  const heading = toStringOrNull(data.heading);
  const content = cleanHtml(data.content);
  const link = parseLinkField(data.linkField);
  const colorSet = parseColorScheme(data.blockScheme);
  const flipAlignment = toBoolean(data.flipAlignment);

  return (
    <BlockContainer meta={meta} colorSet={colorSet} paper paddingY="5xl" {...props}>
      <Grid rowGutter cx={{ flexDirection: { lg: flipAlignment ? 'row' : 'row-reverse' } }}>
        <Grid.Col offsetMd={!flipAlignment ? 1 : 'none'} md={5}>
          {data.video ? <Video videoUrl={data.video} /> : <ImageAsset data={data.imageSingle} />}
        </Grid.Col>
        <Grid.Col md={6} offsetMd={flipAlignment ? 1 : 'none'} cx={{ mY: 'auto' }}>
          <BlockHeader heading={heading} content={content} ContentProps={{ autoMargin: true }}>
            {link && (
              <Btn
                variant="text"
                color="blue"
                size="normal"
                underline="hover"
                endIcon="external"
                {...link}
              />
            )}
          </BlockHeader>
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default TwoColumnContentBlock;
